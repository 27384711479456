import { format } from "@/utils"
import qrCode from "qrcode"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "Security",
  components: { ThemisDecision },
  data() {
    return {
      showPassword: {
        current: undefined,
        new    : undefined
      },
      password: {
        current: undefined,
        new    : undefined
      },
      showPasswordRules : false,
      passwordValidation: {
        minimumCharactersPresent         : false,
        minimumNumbersPresent            : false,
        minimumUpperCaseCharactersPresent: false,
        minimumLowerCaseCharactersPresent: false,
        minimumSpecialCharactersPresent  : false
      },
      isPasswordValid     : false,
      mfaStatus           : undefined,
      showEnableMFADialog : false,
      qrCodeUrl           : undefined,
      showSecretCode      : false,
      totp                : undefined,
      showDisableMFADialog: false
    }
  },
  props: {
    pIsChangingPassword         : Boolean,
    pIsPasswordChanged          : Boolean,
    pIsInvalidCredentials       : Boolean,
    pIsMfaEnabledForLoggedInUser: Boolean,
    pSecretCode                 : String,
    pIsTokenVerified            : Boolean,
    pIsVerifyingToken           : Boolean,
    pIsInvalidTotp              : Boolean,
    pIsMfaEnabled               : Boolean
  },
  emits: [
    "changePassword",
    "generateSharedSecretCode",
    "verifySecretToken",
    "assignUserMfaPreference"
  ],
  methods: {
    handleChangePassword(password) {
      this.$emit("changePassword", password)
    },
    handleMFAChange() {
      if (this.mfaStatus) {
        this.$emit("generateSharedSecretCode")
        this.showEnableMFADialog = true
      } else {
        this.totp                 = undefined
        this.showEnableMFADialog  = false
        this.showDisableMFADialog = true
      }
    },
    resetUserMfaPreference() {
      this.showDisableMFADialog = false
      this.showEnableMFADialog  = false
      this.totp                 = undefined
      this.mfaStatus            = this.pIsMfaEnabledForLoggedInUser
    }
  },
  watch: {
    "password.current": {
      handler: function() {
        if (this.pIsInvalidCredentials) {
          this.$emit("setInvalidCredentials", false)
        }
      }
    },
    "password.new": {
      handler: function(newValue) {
        this.passwordValidation = {
          minimumCharactersPresent         : newValue?.length >= 12,
          minimumNumbersPresent            : newValue?.match(/\d/),
          minimumUpperCaseCharactersPresent: newValue?.match(/[A-Z]/),
          minimumLowerCaseCharactersPresent: newValue?.match(/[a-z]/),
          minimumSpecialCharactersPresent  : newValue?.match(/[!@#$%^&*]/)
        }
        this.isPasswordValid    = this.passwordValidation.minimumCharactersPresent &&
        this.passwordValidation.minimumNumbersPresent &&
        this.passwordValidation.minimumUpperCaseCharactersPresent &&
        this.passwordValidation.minimumLowerCaseCharactersPresent &&
        this.passwordValidation.minimumSpecialCharactersPresent
      }
    },
    pIsMfaEnabledForLoggedInUser: {
      immediate: true,
      handler  : function(newValue) {
        this.mfaStatus = newValue
      }
    },
    pIsTokenVerified: {
      handler: function(newValue) {
        if (newValue) {
          this.$emit("assignUserMfaPreference", true)
          this.showEnableMFADialog = false
        }
      }
    },
    pSecretCode: {
      handler: function(newValue) {
        if (newValue) {
          const otpAuthUrl = format(this.$CONSTANTS.MFA.TOTP_URL, window.location.host, `secret=${newValue}`)
          qrCode.toDataURL(otpAuthUrl)
            .then(url => this.qrCodeUrl = url)
            .catch(() => this.qrCodeUrl = undefined)
        }
      }
    },
    pIsPasswordChanged: {
      handler: function(newValue) {
        if (newValue) {
          this.showPassword = {
            current: undefined,
            new    : undefined
          }
          this.password     = {
            current: undefined,
            new    : undefined
          }
          this.$refs.form_change_password.reset()
        }
      }
    },
    pIsMfaEnabled: {
      handler: function(newValue) {
        newValue ? this.showEnableMFADialog = false : this.showDisableMFADialog = false
      }
    }
  }
}